import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import LoadingSpinner from './LoadingSpinner.jsx'
import { Egate } from "./Landing_PAGE/Egate";
import JsonFetchingFromBlob from "./Blob/JsonFetchingFromBlob.jsx";
import SectionADocumentCreation from "./components/ug_homepage/components/Downloads/SectionADocumentCreation.jsx";
import SectionBDocumentCreation from "./components/ug_homepage/components/Downloads/SectionBDocumentCreation.jsx";
import Management from "./components/ug_homepage/components/Downloads/Upload Formats/Management.jsx";
import {ExamTerminated} from "./MockTest/ExamTerminated.jsx"
const DocumentUpload = React.lazy(() =>
  import("./components/ug_homepage/components/Downloads/DocumentUpload.jsx")
);
// const Interviewtestform = React.lazy(() =>
//   import("./eGRADTestinterface/Interviewtestform.jsx")
// );
// const Egradtest = React.lazy(() =>
//   import("./eGRADTestinterface/Egradtest.jsx")
// );

const CovertJson = React.lazy(() => import("./MockTest/CovertJson.jsx"));

const StudentForm = React.lazy(() => import("./MockTest/StudentForm.jsx"));
const GeneralInstructionsPage = React.lazy(() =>
  import("./MockTest/GeneralInstructionsPage")
);

const UgExamHomePage = React.lazy(() =>
  import("./ExamHomePage/UgExamHomePage.jsx")
);
const OnlineRecordedLecturesHomePage = React.lazy(() =>
  import(
    "./ExamHomePage/OnlineRecordedLecturesHomePages/OnlineRecordedLecturesHomePage.jsx"
  )
);
const OnlineVideoLecturesHomePage = React.lazy(() =>
  import("./OnlineVideoLecturesHomePage/OnlineVideoLecturesHomePage.jsx")
);
const OnlineTestSerieshome = React.lazy(() =>
  import("./OnlineTestSeriesHomePages/OnlineTestSerieshome.jsx")
);
const UG_HOME = React.lazy(() => import("./components/ug_homepage/UG_HOME"));
const Download = React.lazy(() =>
  import("./components/ug_homepage/components/Downloads/Download.jsx")
);
const IitjeeDownloadPage = React.lazy(() =>
  import("./components/ug_homepage/components/Downloads/IitjeeDownloadPage.jsx")
);
const Pg_home_page = React.lazy(() =>
  import("./components/PG/PG_homePage/Pg_home_page").then((module) => ({
    default: module.Pg_home_page,
  }))
);
const State = React.lazy(() =>
  import("./components/PG/context/State.jsx").then((module) => ({
    default: module.State,
  }))
);
const ScrollToTop = React.lazy(() => import("./Landing_PAGE/ScrollToTop.js"));
const PG_DOWNLOAD = React.lazy(() =>
  import("./components/PG/PG DOWNLOADS/PG_DOWNLOAD.jsx")
);
const GateDownloadPage = React.lazy(() =>
  import("./components/PG/PG DOWNLOADS/GateDownloadPage.jsx")
);
const Pricing_Policy = React.lazy(() =>
  import("./components/UG/Pricing_Policy.jsx")
);
const Contact = React.lazy(() =>
  import("./components/ug_homepage/components/Contact/Contact.js")
);
const About_us = React.lazy(() =>
  import("./components/ug_homepage/components/About_section/About_us.jsx")
);
const TERMsCONDITIONS = React.lazy(() =>
  import("./components/UG/TERMsCONDITIONS").then((module) => ({
    default: module.TERMsCONDITIONS,
  }))
);
const Privacypolicy = React.lazy(() =>
  import("./components/UG/Privacypolicy").then((module) => ({
    default: module.Privacypolicy,
  }))
);
const CancellationRefundPolicy = React.lazy(() =>
  import("./components/UG/CancellationRefundPolicy.jsx")
);
const JeeVideoLectures = React.lazy(() =>
  import("./VideoLectures/JeeVideoLectures.jsx")
);
const JeeMathsTopics = React.lazy(() =>
  import("./VideoLectures/JeeMathsTopics.jsx")
);

const OurOfferingsPage = React.lazy(() =>
  import("./Landing_PAGE/OurOfferingsPage.jsx")
);
const Careers_file = React.lazy(() =>
  import("./Landing_PAGE/Careers_file.jsx")
);

const OnlineTestSeriesRootFile = React.lazy(() =>
  import("./MockTest/OnlineTestSeriesRootFile")
);


const OTSViewReport = React.lazy(() => import("./MockTest/OTSViewReport"));
const ExamInstructions = React.lazy(() =>
  import("./MockTest/ExamInstructionsPage.jsx")
);

const App = () => {


  return (
    <State>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* This is a link for landing page */}
          <Route path="/" element={<Egate />} />
          <Route
            path="/OurOfferingsPage"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <OurOfferingsPage />
              </Suspense>
            }
          />
          <Route
            path="/Careers_file"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <Careers_file />
              </Suspense>
            }
          />

          {/* ********************* UG EXAM HOME PAGES ************************************* */}
          <Route
            path="/UGHome"
            element={
              <Suspense fallback={<div>Loading UG Home page...</div>}>
                <UG_HOME />
              </Suspense>
            }
          />
          <Route
            path="/UgExamHomePage/:examId"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <UgExamHomePage />
              </Suspense>
            }
          />
          <Route
            path="/OnlineRecordedLecturesHomePage/:examId"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <OnlineRecordedLecturesHomePage />
              </Suspense>
            }
          />

          <Route
            path="/OnlineTestSerieshome/:examId"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <OnlineTestSerieshome />
              </Suspense>
            }
          />
          <Route
            path="/OnlineVideoLecturesHomePage/:examId"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <OnlineVideoLecturesHomePage />
              </Suspense>
            }
          />
          <Route
            path="/PGHome"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <Pg_home_page />
              </Suspense>
            }
          />
          {/* *********************************FOOTER FILES********************* */}
          <Route
            path="/contactUs"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/About_us"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <About_us />
              </Suspense>
            }
          />
          <Route
            path="/terms"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <TERMsCONDITIONS />
              </Suspense>
            }
          />
          <Route
            path="/CancellationRefundPolicy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <CancellationRefundPolicy />
              </Suspense>
            }
          />
          <Route
            path="/policy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                {" "}
                <Privacypolicy />
              </Suspense>
            }
          />
          <Route
            path="/pricingpolicy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Pricing_Policy />{" "}
              </Suspense>
            }
          />
          {/* *****************************FOOTER FILES END*************************  */}
          <Route
            path="/CovertJson"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <CovertJson />
              </Suspense>
            }
          />

          <Route
            path="/download"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Download />
              </Suspense>
            }
          />
          <Route
            path="/IitjeeDownloadPage/:id"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <IitjeeDownloadPage />
              </Suspense>
            }
          />

          <Route
            path="/UGMockTest"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <StudentForm />
              </Suspense>
            }
          />
          <Route
            path="/GeneralInstructionsPage/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <GeneralInstructionsPage />
              </Suspense>
            }
          />
          <Route
            path="/pgdownload"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PG_DOWNLOAD />
              </Suspense>
            }
          />
          <Route
            path="/gatedownload/:id"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <GateDownloadPage />
              </Suspense>
            }
          />

          <Route
            path="/VideoLectures"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <JeeVideoLectures />
              </Suspense>
            }
          />
          <Route
            path="/JeeMathsTopics/:exam/:subject"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <JeeMathsTopics />
              </Suspense>
            }
          />
<Route path='/ExamTerminated' element={<ExamTerminated/>}/>
          {/* <Route
            path="/eGRADTutorTestInterface"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Interviewtestform />
              </Suspense>
            }
          />
          <Route
            path="/eGRADTutorTestInterface/501"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Egradtest />
              </Suspense>
            }
          /> */}
          <Route path="/blob" element={<JsonFetchingFromBlob />} />
          <Route
            path="/DocumentUpload"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <DocumentUpload />
              </Suspense>
            }
          />
          <Route
            path="/SectionADocumentCreation"
            element={<SectionADocumentCreation />}
          />
          <Route
            path="/SectionBDocumentCreation"
            element={<SectionBDocumentCreation />}
          />

          <Route
            path="/MockTest/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <OnlineTestSeriesRootFile />
              </Suspense>
            }
          />

          <Route path="/OTSViewReport/:examId/:mockTestId/:mockTestTypeId" element={<OTSViewReport />} />
          <Route
            path="/JeeMainsInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <ExamInstructions />
              </Suspense>
            }
          />
          <Route
            path="/JeeAdvanceInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <ExamInstructions />
              </Suspense>
            }
          />
          <Route
            path="/BitsatInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <ExamInstructions />
              </Suspense>
            }
          />
          <Route
            path="/NeetInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <ExamInstructions />
              </Suspense>
            }
          />
          <Route path="/UploadFormats" element={<Management/>}/>
        </Routes>
      </Router>
    </State>
  );
};

export default App;
