import React, {useState} from 'react'
import "./CssFiles/MockTest.css";
export const ExamTerminated = () => {
     const [terminationPopup, setTerminationPopup] = useState(false);
    const closePopupTermination = () => {
        setTerminationPopup(false);
        window.close();
      };
  return (
    <div className="terminationPopUp">
    <div>
      <h2>Exam Terminated</h2>
      <p>
        Your exam session has been terminated due to inactivity.
      </p>
      <button onClick={closePopupTermination} className="cancelbtn" >Close</button>
    </div>
  </div>
  )
}
