import React, { useEffect, useState } from 'react'

const JsonFetchingFromBlob = () => {
    const fileUrl = `https://egradstorage.blob.core.windows.net/downloads-json-files1/JeeMains_Two.json`;
    const newUrl = `https://egradstorage.blob.core.windows.net/downloads-json-files1/JeeMains_Two.json?sp=r&st=2024-12-10T08:36:54Z&se=2024-12-10T16:36:54Z&spr=https&sv=2022-11-02&sr=b&sig=3affTDEu9Ocy5xQzaj3f9SX4xsltdCkArZ2fuY1Ne2M%3D`
    const [testData, setTestData] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        console.log(testData, "this is test data")
    })
    useEffect(() => {
        const fetchFile = async () => {
            try {
                const response = await fetch(newUrl);
                if (!response.ok) {
                    throw new Error(`Failed to fetch file: ${response.statusText}`,);
                }
                const content = await response.text();
                console.log(Array.isArray(content))
                const contentArray = JSON.parse(content);
                console.log(typeof contentArray)
                if (contentArray && contentArray.sections) {
                    contentArray.sections.forEach(section => {
                        if (section.questions && Array.isArray(section.questions)) {
                            section.questions.map((item) => {
                                console.log(item.question);  // Access and log the question
                            });
                        } else {
                            console.log("No questions found in this section.");
                        }
                    });
                } else {
                    console.log("No sections found.");
                }
                console.log(content)
                setTestData(contentArray)
                setLoading(false)
            } catch (error) {
                console.log(error, "error while fetcig from blob");
                setLoading(true)
            }
        }
        fetchFile()
    }, [])

    if (loading) {
        return <p>Loading questions.......</p>
    }
const handleSubjectClick=()=>{

}

    return (
        <div>
            JsonFetchingFromBlob
            {testData && testData.length > 0 ? (
                testData.map((test, testIndex) => (
                    <div key={testIndex}>
                        <h2>{test.Test_Name} ({test.SubjectName})</h2>
                        {test.sections.map((section, sectionIndex) => (
                            <div key={sectionIndex}>
                                <h3>{section.SectionName}</h3>
                                {section.questions.map((question, questionIndex) => (
                                    <div key={questionIndex}>
                                        <h4>Question {question.question_id}</h4>
                                        {question.questionImgName && (
                                            <img
                                                src={question.questionImgName}
                                                alt={`Question ${question.question_id}`}
                                                style={{ maxWidth: '100%', height: 'auto' }}
                                            />
                                        )}
                                      
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <p>No tests available</p>
            )}

            {testData.map((item,index) => (
                <button key={index} onClick={handleSubjectClick(item.SubjectName)}>
                    {item.SubjectName}
                </button>
            ))}

        </div>
    )
}

export default JsonFetchingFromBlob