import React, { useState } from "react";
import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, ImageRun } from "docx";
 
const SectionBDocumentCreation = () => {
    const [solutionImage, setSolutionImage] = useState(null);
    const [questions, setQuestions] = useState([
        {
            questionImage: null,
            solutionImage: null,
            answer: "",
        },
    ]);
 
    const [state, setState] = useState({ imageWidth: undefined, imageHeight: undefined });
    const transformationWidth = 600; // Desired width
    const imageWidth = state.imageWidth; // Replace with actual state value or input
    const imageHeight = state.imageHeight; // Replace with actual state value or input
 
    const transformationHeight = Math.round(
        (transformationWidth / imageWidth) * imageHeight
    );
 
    const processImage = (imageData, maxWidth, maxHeight) => {
        const img = new Image();
        img.src = imageData;
 
        return new Promise((resolve) => {
            img.onload = () => {
                const { naturalWidth, naturalHeight } = img;
                let width = naturalWidth;
                let height = naturalHeight;
 
                if (naturalWidth > maxWidth || naturalHeight > maxHeight) {
                    if (naturalWidth / maxWidth > naturalHeight / maxHeight) {
                        width = maxWidth;
                        height = Math.round((naturalHeight / naturalWidth) * maxWidth);
                    } else {
                        height = maxHeight;
                        width = Math.round((naturalWidth / naturalHeight) * maxHeight);
                    }
                }
 
                resolve({ width, height });
            };
        });
    };
    const handleAnswerChange = (index, newAnswer) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answer = newAnswer;
        setQuestions(updatedQuestions);  
       
    };
   
    // In the handleSave function, ensure the answer is correctly passed:
    const handleSave = async () => {
        let questionCounter = 21;
        let sortid = 21;
   
        const questionMaxWidth = 600;
        const questionMaxHeight = 900;
   
        const docSections = [];
   
        for (let index = 0; index < questions.length; index++) {
            const question = questions[index];
    console.log("quetion",question.answer)
            const questionImageTransform = question.questionImage
                ? await processImage(question.questionImage, questionMaxWidth, questionMaxHeight)
                : null;
   
            const solutionImageTransform = question.solutionImage
                ? await processImage(question.solutionImage, questionMaxWidth, questionMaxHeight)
                : null;
   
            docSections.push({
                children: [
                    new Paragraph(`[Q]`),
                    questionImageTransform &&
                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: question.questionImage ? question.questionImage.split(",")[1] : "",
                                    transformation: questionImageTransform
                                        ? {
                                              width: questionImageTransform.width,
                                              height: questionImageTransform.height,
                                          }
                                        : undefined,
                                }),
                            ],
                        }),
                    new Paragraph(`[qtype] nat`),
                    new Paragraph(`[Marks] 4,1`),
                    new Paragraph(`[sortid] ${sortid++}`),
                    new Paragraph(`[ans] ${question.answer}`),
                    new Paragraph("[soln]"),
                    solutionImageTransform &&
                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: question.solutionImage ? question.solutionImage.split(",")[1] : "",
                                    transformation: solutionImageTransform,
                                }),
                            ],
                        }),
                 
                ].filter(Boolean),
            });
        }
      docSections.push({
               children: [new Paragraph(`[QQ]`) ],
           });
        // Create the Word document
        const doc = new Document({
            sections: docSections,
        });
   
        try {
            const blob = await Packer.toBlob(doc);
            saveAs(blob, "question_with_options.docx");
            alert("Document has been downloaded successfully!");
        } catch (error) {
            console.error("Error creating the document:", error);
        }
    };
   
 
    const handlePaste = (e, setImage, index) => {
        e.preventDefault();
        const clipboardItems = e.clipboardData.items;
        for (let i = 0; i < clipboardItems.length; i++) {
            if (clipboardItems[i].type.startsWith("image/")) {
                const file = clipboardItems[i].getAsFile();
                const reader = new FileReader();
                reader.onload = () => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[index].solutionImage = reader.result;
                    setQuestions(updatedQuestions);
                };
                reader.readAsDataURL(file);
                break;
            }
        }
    };
 
    const addNewQuestion = () => {
        if (questions.length < 10) {
        setQuestions([
            ...questions,
            {
                questionImage: null,
                solutionImage: null,
                answer: "",
            },
        ]);
    } else {
        alert("You can only add up to 20 questions.");
    }
    };
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", maxWidth: "600px", margin: "auto" }}>
            <h2>Paste Question and Options</h2>
            {questions.map((question, index) => (
                <div key={index} style={{ marginBottom: "30px" }}>
                    <h3>Question {index + 21}</h3>
 
                    {/* Paste Image for Question */}
                    <div style={{ marginBottom: "20px" }}>
                        <h3>Paste Image for Question</h3>
                        <div
                            style={{
                                border: "1px dashed #ccc",
                                padding: "10px",
                                textAlign: "center",
                                cursor: "pointer",
                            }}
                            onPaste={(e) => {
                                const clipboardItems = e.clipboardData.items;
                                for (let i = 0; i < clipboardItems.length; i++) {
                                    if (clipboardItems[i].type.startsWith("image/")) {
                                        const file = clipboardItems[i].getAsFile();
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            const updatedQuestions = [...questions];
                                            updatedQuestions[index].questionImage = reader.result;
                                            setQuestions(updatedQuestions);
                                        };
                                        reader.readAsDataURL(file);
                                        break;
                                    }
                                }
                            }}
                        >
                            {question.questionImage ? (
                                <img src={question.questionImage} alt={`Question ${index + 1}`} style={{ maxWidth: "100%", height: "auto" }} />
                            ) : (
                                "Paste your question image here (Ctrl+V)"
                            )}
                        </div>
                    </div>
 
                    {/* Paste Solution Image */}
                    <div style={{ marginBottom: "20px" }}>
                        <h3>Solution</h3>
                        <div
                            style={{ border: "1px dashed #ccc", padding: "10px", cursor: "pointer" }}
                            onPaste={(e) => handlePaste(e, setSolutionImage, index)}
                        >
                            {question.solutionImage ? (
                                <img src={question.solutionImage} alt={`Solution ${index + 1}`} style={{ maxWidth: "100%" }} />
                            ) : (
                                "Paste your solution image here"
                            )}
                        </div>
                    </div>
 
                    {/* Answer Input */}
                    <div style={{ marginBottom: "20px" }}>
                        <h3>Enter Answer</h3>
                        <input
                            type="text"
                            value={question.answer}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            style={{
                                width: "100%",
                                padding: "8px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                            }}
                            placeholder="Enter alphabet, number, or symbol"
                        />
                    </div>
                </div>
            ))}
 
            <button
                onClick={addNewQuestion}
                style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#28a745",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            >
                Add Another Question
            </button>
 
            <button
                onClick={handleSave}
                style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            >
                Save to Word
            </button>
        </div>
    );
};
 
export default SectionBDocumentCreation;